const navButton = $("#navButton");
const closeButton = $("#closeNav");
const mainNav = $("#mainNav");
const body = $("body");

function hideNav() {
  console.log("hideNav");
  mainNav.fadeOut(200);
  navButton.removeAttr("aria-expanded");
  body.removeClass("nav-expanded");
}

function showNav() {
  console.log("showNav");
  mainNav.css("display", "flex");
  mainNav.hide();
  mainNav.fadeIn(200);
  navButton.attr("aria-expanded", "true");
  body.addClass("nav-expanded");
}

navButton.on("click", function () {
  showNav();
});

closeButton.on("click", function () {
  hideNav();
});
