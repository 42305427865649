var cursor = document.querySelector(".cursor");
var cursorinner = document.querySelector(".cursor2");
var a = document.querySelectorAll("a");
var button = document.querySelectorAll("button");

document.addEventListener("mouseleave", (event) => {
  if (event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)) {
    cursor.classList.remove("cursorinit");
  }
});

document.addEventListener("mousemove", function (e) {
  var x = e.clientX;
  var y = e.clientY;
  cursor.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`;
  cursor.classList.add("cursorinit");
});

document.addEventListener("mousemove", function (e) {
  var x = e.clientX;
  var y = e.clientY;
  cursorinner.style.left = x + "px";
  cursorinner.style.top = y + "px";
});

document.addEventListener("mousedown", function () {
  cursor.classList.add("cursoractive");
  cursorinner.classList.add("cursorinneractive");
});

document.addEventListener("mouseup", function () {
  cursor.classList.remove("cursoractive");
  cursorinner.classList.remove("cursorinneractive");
});

a.forEach((item) => {
  mouseHoverState(item);
});

button.forEach((item) => {
  mouseHoverState(item);
});

function mouseHoverState(item) {
  item.addEventListener("mouseover", () => {
    cursor.classList.add("cursorhover");
    cursorinner.classList.add("cursorinnerhover");
  });
  item.addEventListener("mouseleave", () => {
    cursor.classList.remove("cursorhover");
    cursorinner.classList.remove("cursorinnerhover");
  });
}
