if (!localStorage.getItem("dsBackgroundColour")) {
  localStorage.setItem("dsBackgroundColour", "#06f216");
}

document.documentElement.style.setProperty(
  "--backgroundColour",
  localStorage.getItem("dsBackgroundColour")
);

const themePicker = document.querySelector("#themePicker");
themePicker.value = localStorage.getItem("dsBackgroundColour");

themePicker.addEventListener("change", function () {
  document.documentElement.style.setProperty("--backgroundColour", this.value);
  localStorage.setItem("dsBackgroundColour", this.value);
});

document.body.classList.add("theme-loaded");

if (!localStorage.getItem("dsCustomCursorEnabled")) {
  localStorage.setItem("dsCustomCursorEnabled", true);
}

const customCursorCheckbox = document.querySelector("#customCursorCheckbox");
customCursorCheckbox.checked = localStorage.getItem("dsCustomCursorEnabled") == "true";
setCustomCursorBodyClass();

customCursorCheckbox.addEventListener("change", function () {
  localStorage.setItem("dsCustomCursorEnabled", this.checked);
  setCustomCursorBodyClass();
});

function setCustomCursorBodyClass() {
  if (localStorage.getItem("dsCustomCursorEnabled") == "true") {
    document.body.classList.add("custom-cursor-enabled");
  } else {
    document.body.classList.remove("custom-cursor-enabled");
  }
}

var modal = document.getElementById("modal");
if (modal) {
  var closeModalBtn = document.getElementsByClassName("close-btn")[0];
  closeModalBtn.onclick = function() {
    modal.style.display = "none";
  }
  // When the user clicks anywhere outside of the modal, close it
  window.onclick = function(event) {
    if (event.target == modal) {
      modal.style.display = "none";
    }
  }
}
